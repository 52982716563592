import React from 'react'
import { FaFacebook } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import { IoLogoTiktok } from "react-icons/io5";
import { RiTwitterXLine } from "react-icons/ri";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <React.Fragment>
            <div className="flex flex-col lg:flex-row items-center justify-between bg-[#2e3a45] xl:px-28 px-6 text-white w-full py-5">
                <div className="w-full mb-8 lg:mb-0 text-center lg:text-left">
                    <h1 className="text-2xl font-bold">Quality</h1>
                    <p className="text-md pt-5 pb-8 lg:pb-16 w-full lg:w-[50%] mx-auto lg:mx-0">
                        Trusted bouquet shop with 24x7 customer support.
                    </p>
                    <div className="flex text-3xl gap-5 justify-center lg:justify-start">
                        <FaFacebook />
                        <IoLogoInstagram />
                        <IoLogoTiktok />
                        <RiTwitterXLine />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-around items-center sm:items-start w-full xl:gap-16 gap-8">
                    <div className="w-full mb-6 sm:mb-0 text-center lg:text-left">
                        <h1 className="text-sm font-bold uppercase">service</h1>
                        <h2 className="mt-5">+91-88299 09900</h2>
                        <p className="text-lg">contact@buddybook.com</p>
                    </div>
                    <div className="w-full text-center lg:text-left">
                        <h1 className="text-sm font-bold uppercase">Support</h1>
                        <div className="w-full mt-5">
                            <h1 className="text-md">Enter your email address</h1>
                            <input
                                type="text"
                                placeholder="Your email for updates"
                                className="py-2 px-5 bg-white placeholder:text-gray-400 rounded-lg w-full mt-2"
                            />
                        </div>
                        <div className="flex justify-center lg:justify-start">
                            <button className="mt-4 py-2 px-4 lg:px-6 bg-[#4a6fa5] text-white rounded-full w-full sm:w-[80%]">
                                Submit Inquiry
                            </button>
                        </div>
                    </div>
                </div>
            </div>
           
            <footer className="footer bg-gray-800 shadow text-white px-5 py-4">
                <div className="container mx-auto">
                    <div className="flex flex-col sm:flex-row justify-between">
                        <div className="w-full sm:w-1/2 text-center sm:text-left mb-2 sm:mb-0">
                            {currentYear} © <span className=''>All Rights Reserved Buddy Book</span>.
                        </div>
                        <div className="w-full sm:w-1/2 text-center sm:text-right">
                            <div className="text-sm hidden sm:block">
                                Design & Develop by
                                <a href="https://www.webbocket.com/" className="ms-1 underline">
                                    Web-Bocket
                                </a>
                            </div>
                            <div className="text-sm block sm:hidden">
                                Design & Develop by <a href="https://www.webbocket.com/" className="underline">Web-Bocket</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer