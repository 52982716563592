import React from "react";
import logo from "../../assets/logo-1.gif";
const Navbar = () => {
  return (
    <nav className="bg-black text-white px-16 py-6">
      {/* <img src={logo} alt='logo' className='h-24 md:ml-40 mx-auto'/> */}
      <div
        style={{ fontFamily: "cursive" }}
        className="md:text-4xl text-2xl text-yellow-500 tracking-wide"
      >
        Buddy Book
      </div>
    </nav>
  );
};

export default Navbar;
