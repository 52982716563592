import React from 'react'
import Hero from '../../Components/homecomponents/Hero'
import WhatsappComponent from '../../Components/homecomponents/WhatsappComponent'
import BuddyBooksDesign from '../../Components/homecomponents/BuddyBooksDesign'
import BuddyBooksExperience from '../../Components/homecomponents/BuddyBooksExperience'

const Home = () => {
  return (
    <>
      <Hero/>
      <BuddyBooksDesign/>
      <BuddyBooksExperience/>
      <WhatsappComponent/>
    </>
  )
}

export default Home
