import React from "react";
import NotFound from "../Screens/Common/NotFound";
import Home from "../Screens/User/Home";


const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "*", component: <NotFound /> },
];

export { userRoutes };
