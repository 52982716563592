import React from 'react';
import img from '../../assets/imgs.avif';

const BuddyBooksDesign = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 font-sans mt-6 sm:mt-10">
      {/* Top Section */}
      <div className="flex flex-col md:flex-row gap-4 sm:gap-6 md:gap-8 mb-8 md:mb-12">
        {/* Left Column - Title */}
        <div className="w-full md:w-1/2">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-4 sm:mb-6">
            Introduction to Buddy Books
          </h1>
        </div>

        {/* Right Column - Description */}
        <div className="w-full md:w-1/2">
          <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
            In today's fast-paced world, finding a reliable source for
            reading material can be a daunting task. Buddy Books
            stands out as a premier provider of literature that not only
            meets but exceeds user expectations. Known for its
            commitment to delivering 100% original content, this service
            offers an experience that is both easy and convenient.
          </p>
        </div>
      </div>

      {/* Stats Section */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-12 mb-8 md:mb-12">
        <div className="hidden md:block">
          {/* Empty div for layout purposes */}
        </div>
        <div className="hidden md:block">
          {/* Empty div for layout purposes */}
        </div>
        
        {/* First Stat */}
        <div className="col-span-1">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-blue-500 mb-1 sm:mb-2">
            150+
          </h2>
          <p className="text-sm sm:text-base text-gray-700">
            Trusted Brand
          </p>
        </div>

        {/* Second Stat */}
        <div className="col-span-1">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-blue-500 mb-1 sm:mb-2">
            15
          </h2>
          <p className="text-sm sm:text-base text-gray-700">
            Best Service<br className="hidden sm:block" />
            Guaranteed
          </p>
        </div>
      </div>
      
      {/* Image Section */}
      <div className="w-full px-4 sm:px-0">
        <img 
          src={img} 
          alt='Buddy Books' 
          className=' mx-auto rounded-md shadow-md'
        />
      </div>
    </div>
  );
};

export default BuddyBooksDesign;