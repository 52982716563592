import React from 'react';

const BuddyBooksExperience = () => {
    const openMessageModal = () => {
        const phoneNumber = "+918829909900";
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, "_blank");
      };
    return (
        <div className="container mx-auto px-4">
            <section className="flex flex-col items-center py-8 md:py-12">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 md:mb-6 text-center">The Buddy Books Experience</h1>
                <div className="prose max-w-full md:max-w-3xl lg:max-w-4xl text-center">
                    <p className="text-base md:text-lg text-gray-700 mb-4">
                        At Buddy Books, users can expect a seamless process tailored to their literary needs.
                        The platform is designed to provide access to a wide range of titles, ensuring that readers
                        can effortlessly find the books they are interested in. Convenience is a key feature of the
                        service, allowing users to browse, select, and purchase their preferred literature with minimal hassle.
                    </p>
                    <p className="text-base md:text-lg text-gray-700">
                        The team at Buddy Books prides itself on being available 24 hours a day, ensuring
                        that assistance is always at hand. Whether you have questions about a specific title or
                        require support during the purchasing process, their quick and reliable team is ready to help.
                        This level of customer service reinforces the trust that users place in Buddy Books,
                        making it a preferred choice among avid readers.
                    </p>
                </div>
            </section>

            <section className="flex flex-col items-center py-8 md:py-12">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 md:mb-6 text-center">Commitment to Quality and Authenticity</h1>
                <div className="prose max-w-full md:max-w-3xl lg:max-w-4xl text-center">
                    <p className="text-base md:text-lg text-gray-700 mb-4">
                        One of the cornerstones of Buddy Books is its unwavering commitment to providing only original literature. In an era where authenticity is often compromised, this service ensures that every book offered is genuine and of high quality. This dedication to authenticity not only gives readers peace of mind but also fosters a trustworthy relationship between the platform and its users.
                    </p>
                    <p className="text-base md:text-lg text-gray-700">
                        Additionally, the quick and efficient nature of Buddy Books means that there are no long waiting periods for your literary selections. From the moment you place your order to the timely delivery of your chosen books, the entire process is designed to ensure satisfaction and ease. This efficiency is critical in establishing a robust service that readers can depend on at any hour.
                    </p>
                </div>
            </section>

            <section className="flex flex-col items-center py-8 md:py-12">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 md:mb-6 text-center">Conclusion</h1>
                <div className="prose max-w-full md:max-w-3xl lg:max-w-4xl text-center">
                    <p className="text-base md:text-lg text-gray-700 mb-4">
                        In summary, Buddy Books has earned its reputation as a trusted service in the world of literature. With a focus on providing 100% original sources, an easy and convenient purchasing process, and a proactive support team available 24 hours, users receive an exceptional experience. For those seeking reliability and quality in their reading material, Buddy Books is undoubtedly the go-to choice, embodying everything one could wish for in a modern literary service.
                    </p>
                </div>
                <button className="bg-green-500 text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-green-600 transition-colors mt-4 md:mt-6"   onClick={openMessageModal}>
                    WhatsApp Now
                </button>
            </section>
        </div>
    );
}

export default BuddyBooksExperience;