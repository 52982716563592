import React from 'react';
import img1 from '../../assets/cardimg1.avif';
import img2 from '../../assets/cardimg2.avif';

const Hero = () => {
    const openMessageModal = () => {
        const phoneNumber = "+918829909900";
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, "_blank");
      };
    return (
        <div className="bg-green-100 min-h-screen flex flex-col items-center justify-center text-center py-10 px-4 sm:px-6 md:px-8">
            <button className="bg-green-500 text-white px-4 py-2 rounded-md mb-4 sm:mb-5 hover:bg-green-600 transition-colors"   onClick={openMessageModal}>
                WhatsApp Now
            </button>
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 sm:mb-5 w-full sm:max-w-[85%] md:max-w-[75%] lg:max-w-[70%] ">
                Buddy Books is Your Trusted and Only Original Source.
            </h1>
            <div className="flex flex-col w-full sm:max-w-[90%] md:max-w-[85%] lg:max-w-[70%] mt-6 sm:mt-8 md:mt-10 px-4 sm:px-6 space-y-5 md:space-y-0 md:flex-row md:space-x-6 lg:space-x-10">
                <div className="bg-white shadow-lg rounded-2xl overflow-hidden w-full">
                    <img className="w-full h-56 sm:h-64 md:h-72 object-cover" src={img1} alt="quality service" />
                    <div className="p-4 sm:p-5">
                        <h2 className="font-semibold text-lg sm:text-xl">24x7 Customer Support</h2>
                        <p className="text-gray-600 text-sm sm:text-base">
                            Our dedicated team is here to assist you anytime, ensuring a seamless experience with every order.
                        </p>
                    </div>
                </div>
                <div className="bg-white shadow-lg rounded-2xl overflow-hidden w-full">
                    <img className="w-full h-56 sm:h-64 md:h-72 object-cover" src={img2} alt="quality service" />
                    <div className="p-4 sm:p-5">
                        <h2 className="font-semibold text-lg sm:text-xl">Quality Service Offered</h2>
                        <p className="text-gray-600 text-sm sm:text-base">
                            Explore our diverse range of stunning bouquets tailored for every occasion and preference at Buddy Book.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;